import React, { Component } from 'react';
import './sustainable-living.scss';

class SustainableLiving extends Component {
  render() {
    return (
      <div className='sustainable-living-container sustainable-bg'>
        <div className='row'>
          <div className='col-lg-6 col-md-12 col-sm-12 align-self-center text-center'>
            <img
              className='img-fluid'
              src='/assets/imagewebp/home-3/explore-amenities.webp'
              alt=''
            />
          </div>
          <div className='col-lg-6 col-md-12 col-sm-12 align-self-center pb-maxmd'>
            <div className='w-lg-75 mx-auto'>
              <h3 className='text-uppercase text-primary'>NACHHALTIGKEIT</h3>
              <h2 className='text-uppercase mb-4'>
                ÖKOLOGISCH UND EFFIZIENTE BAUWEISE
              </h2>
              <div className='list-container'>
                <div className='list-container-box'>
                  <div className='list-container-icon'>
                    <i className='opal-icon-map'></i>
                  </div>
                  <p className='list-container-text'>
                    Regenwassergewinnung und Wasserrecyling
                  </p>
                </div>
                <div className='list-container-box'>
                  {' '}
                  <div className='list-container-icon'>
                    <i className='opal-icon-map'></i>
                  </div>
                  <p className='list-container-text'>Solarenergy</p>
                </div>
                <div className='list-container-box'>
                  {' '}
                  <div className='list-container-icon'>
                    <i className='opal-icon-map'></i>
                  </div>
                  <p className='list-container-text'>
                    Nutzung von Nachhaltigem Baumaterial
                  </p>
                </div>
                <div className='list-container-box'>
                  {' '}
                  <div className='list-container-icon'>
                    <i className='opal-icon-map'></i>
                  </div>
                  <p className='list-container-text'>
                    Energieeffiziente Beleuchtung
                  </p>
                </div>
                <div className='list-container-box'>
                  {' '}
                  <div className='list-container-icon'>
                    <i className='opal-icon-map'></i>
                  </div>
                  <p className='list-container-text'>Erdsondenenergy</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SustainableLiving;
