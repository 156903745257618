import React from 'react';
import { Link } from 'react-router-dom';
import './logo.scss';

const LogoImage = (props) => {
  return (
    <Link to={`${process.env.PUBLIC_URL}/`} className='custom-logo-link'>
      <span className='screen-reader-text'>MaisonCo Single Property</span>
      <div className='header-logo-container'>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/logo/${props.logo}`}
          alt='logo'
          className='img-fluid'
        />
      </div>
    </Link>
  );
};
export default LogoImage;
