import React from 'react';
import './generation-friendly.scss';

const GenerationFriendly = () => {
  return (
    <div className='location-benefit-container'>
      <div className='row location-benefits'>
        <div className='col-lg-6 col-md-12 col-sm-12 align-self-center pb-maxmd'>
          <div className='w-lg-75 mx-auto'>
            <h3 className='text-uppercase text-primary'>FREUNDLICH UND GRÜN</h3>
            <h2 className='text-uppercase mb-4'>
              GENERATIONEN UND FAMILIEN FREUNDLICH
            </h2>
            <p className='pb-4'>
              In Schinznach entsteht eine familienfreundliche Siedlung fernab
              des Verkehrs. Gestaffelt werden x Mehrfamilienhäuser à x Wohnungen
              sowie drei Einfamilienhäuser gebaut. Ein eigener Garten, ein
              gemütlicher Sitzplatz und viel Platz zum Spielen: Die
              Mietwohnungen in Buurehuus fühlen sich fast so an wie die eigenen
              vier Wände Ein barrierefreies Gebäude entspricht den Ansprüchen
              von Behinderten, Senioren und verringert das Unfallrisiko bei
              kleineren Kindern.
            </p>
          </div>
        </div>
        <div className='col-lg-6 col-md-12 col-sm-12 align-self-center text-center'>
          <img
            className='img-fluid'
            src='/assets/imagewebp/home-3/modern-furniture.webp'
            alt='schinznach-dorf'
          />
        </div>
      </div>
    </div>
  );
};

export default GenerationFriendly;
