import React from 'react';
import './proximity-to-amenities.scss';

const ProximityToAmenities = () => {
  return (
    <div className='proximity-to-amenities-container'>
      <div className='row'>
        <div className='col-lg-6 col-md-12 col-sm-12 align-self-center text-center'>
          <img
            className='img-fluid'
            src='/assets/imagewebp/home-3/explore-amenities.webp'
            alt=''
          />
        </div>
        <div className='col-lg-6 col-md-12 col-sm-12 align-self-center pb-maxmd proxmity-to-amentities-detailed'>
          <div className='w-lg-75 mx-auto'>
            <h3 className='text-uppercase text-primary'>LAGEPLAN</h3>
            <h2 className='text-uppercase mb-4'>ALLES IN IHRER NÄHE</h2>
            <div className='list-container'>
              <div className='list-container-box'>
                <div className='list-container-icon'>
                  <i className='opal-icon-map'></i>
                </div>
                <p className='list-container-text'>Arzt und Apotheke</p>
              </div>
              <div className='list-container-box'>
                <div className='list-container-icon'>
                  <i className='opal-icon-map'></i>
                </div>
                <p className='list-container-text'>Kirche und Friedhof</p>
              </div>
              <div className='list-container-box'>
                <div className='list-container-icon'>
                  <i className='opal-icon-map'></i>
                </div>
                <p className='list-container-text'>Post</p>
              </div>
              <div className='list-container-box'>
                <div className='list-container-icon'>
                  <i className='opal-icon-map'></i>
                </div>
                <p className='list-container-text'>Kindergarten</p>
              </div>
              <div className='list-container-box'>
                <div className='list-container-icon'>
                  <i className='opal-icon-map'></i>
                </div>
                <p className='list-container-text'>Primar- und Hochschule</p>
              </div>
              <div className='list-container-box'>
                <div className='list-container-icon'>
                  <i className='opal-icon-map'></i>
                </div>
                <p className='list-container-text'>Einkaufszentrum</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProximityToAmenities;
