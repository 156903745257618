import React from 'react';
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import Helmet from 'react-helmet';
import './contact.scss';

const ContactPage = () => {
        return (
            <div className={`contact-page-wrap`}>
                <Helmet>
                    <title>MaisonCo | Kontaktieren Sie uns</title>
                </Helmet>
                <Breadcrumb title={'Kontakt'}/>
                <div className="section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <div className="box-icon">
                                    <div className="icon"><i className="opal-icon-phone"></i></div>
                                    <div className="content">
                                        <span className="title text-uppercase">TELEFON:</span>
                                        <p className="description">+41 79 345 02 12</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <div className="box-icon">
                                    <div className="icon"><i className="opal-icon-point"></i></div>
                                    <div className="content">
                                        <span className="title text-uppercase">ADRESSE:</span>
                                        <p className="description">Ausserdorfstrasse 107</p>
                                        <p className="description">5107 Schinznach-Dorf</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <div className="box-icon no-border">
                                    <div className="icon"><i className="opal-icon-envelope"></i></div>
                                    <div className="content">
                                        <span className="title text-uppercase">E-MAIL-ADRESSE:</span>
                                        <p className="description">support@maisonco.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-wrapper">
                    <div className="form-contact mb-5">
                        <h3 className="text-uppercase text-center">KONTAKTFORMULAR</h3>
                        <div className="mt-4 row">
                            <div className="col-6 mb-3">
                                <input type="text" className="form-control" id="firstName" placeholder="Name"/>
                            </div>
                            <div className="col-6 mb-3">
                                <input type="text" className="form-control" id="surname" placeholder="Nachname"/>
                            </div>
                            <div className="col-6 mb-3">
                                <input type="email" className="form-control" id="email" placeholder="E-Mail-Adresse"/>
                            </div>
                            <div className="col-6 mb-3">
                                <input type="telefon" className="form-control" id="telefon" placeholder="Telefon"/>
                            </div>
                            <div className="col-12 mb-3">
                                <textarea id="message" className="form-control" placeholder="Ihre Nachricht" rows="3"></textarea>
                            </div>
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary w-100">SENDEN<i className="opal-icon-arrow"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

export default ContactPage