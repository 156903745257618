import React from 'react';

const Copyright = () => {
    const currentDate = new Date().getFullYear();

        return (
            <div className="copyright">
                <p>Copyright © {currentDate} <a href="#">RollePage</a>. All Rights Reserved.</p>

            </div>
        )
}

export default Copyright