import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const MainMenu = (props) => {
  const {
    menu: { menu },
  } = props;

  const [active, setActive] = useState('Home');

  const handleClick = (menuItem) => {
    setActive(menuItem);
  };

  const menuRender = (menu, key) => {
    let hasSub = menu.children.length ? true : false;
    return (
      <li className={`menu-item`} key={key}>
        <Link
          to={`${process.env.PUBLIC_URL}${menu.link}`}
          className={active === menu.name ? 'selected' : ''}
          onClick={() => handleClick(menu.name)}
        >
          {menu.name}
          {hasSub ? <i className='fa fa-angle-down'></i> : ''}
        </Link>
        {hasSub ? (
          <ul className='sub-menu'>
            {menu.children.map((_menu, _key) => menuRender(_menu, _key))}
          </ul>
        ) : (
          ''
        )}
      </li>
    );
  };

  return (
    <div className='navigation-top'>
      <nav className='main-navigation' aria-label='Top Menu'>
        <div className='mainmenu-container'>
          <ul className='top-menu menu'>
            {menu.map((item, key) => menuRender(item, key))}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default MainMenu;
