import React, { useState, useEffect } from 'react';
import Header from './components/common/header/header';
import Footer from './components/common/footer/footer';
import PushMenu from 'react-push-menu';
import LinkComponent from './lib/menuPush/LinkComponent';
import './lib/menuPush/component.scss';

const App = (props) => {
  const [menu, setMenu] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch('/api/menu.json')
      .then((response) => response.json())
      .then((res) => {
        setMenu(res);
        setIsLoading(false);
        document.querySelector('.loader-wrapper').style = 'display: none';
      });
  }, []);
  console.log({ props });
  return (
    !isLoading && (
      <div className='opal-wrapper'>
        <PushMenu
          backIcon={<i className='fa fa fa-arrow-left'></i>}
          expanderComponent={() => {
            return <i className='fa fa-chevron-right'></i>;
          }}
          autoHide={true}
          nodes={{ children: menu }}
          type={'cover'}
          linkComponent={LinkComponent}
          propMap={{ url: 'link' }}
        >
          <div id='page' className='site'>
            <Header logoName={'logo.svg'} menu={menu} />
            {props.children}
            <Footer logoName={'logo.svg'} />
          </div>
        </PushMenu>
      </div>
    )
  );
};

export default App;
