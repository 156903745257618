/**
 * Mocking client-server processing
 */
import _apartment from './apartment.json';
const TIMEOUT = 100;

export default {
  getApartments: (cb, timeout) =>
    setTimeout(() => cb(_apartment), timeout || TIMEOUT),
};
