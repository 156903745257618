import { combineReducers } from 'redux';
import { IntlReducer as Intl } from 'react-redux-multilingual';

// Import custom components
import apartmentReducer from './apartment';

const rootReducer = combineReducers({
  apartments: apartmentReducer,
  Intl,
});

export default rootReducer;
