import api from '../api/api';

export const receiveApartments = (data) => ({
  type: 'receive_apartments',
  apartments: data,
});

export const getAllData = () => (dispatch) => {
  api.getApartments((apartments) => {
    dispatch(receiveApartments(apartments));
    return apartments;
  });
};
