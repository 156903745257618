import React from 'react';
import './footer.scss';
import Copyright from '../copyright/copyright';

const Footer = () => {
  return (
    <footer className='site-footer'>
      <div className='footer-container'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-sm-6'>
              <div className='logo-container'>
                <img src={`/assets/images/logo/logo2.png`} alt='logo' />
              </div>
            </div>
            <div className='col-lg-6 col-sm-6'>
              <div className='icon-list footer-address'>
                <ul>
                  <li>
                    <span className='icon-list-icon'>ADRESSE:</span>
                    <span className='icon-list-text'>
                      Ausserdorfstrasse 107, 5107 Schinznach-Dorf
                    </span>
                  </li>
                  <li>
                    <span className='icon-list-icon'>TELEFON:</span>
                    <span className='icon-list-text'>+41 79 345 02 12</span>
                  </li>
                  <li>
                    <span className='icon-list-icon'>E-MAIL:</span>
                    <a
                      href='mailto:roland@gmail.com'
                      className='icon-list-text email'
                    >
                      roland@gmail.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='copyright'>
            <Copyright />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
