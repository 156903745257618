import React from 'react';
import './location-benefit.scss';

const LocationBenefit = () => {
  return (
    <div className='location-benefit-container'>
      <div className='row location-benefits'>
        <div className='col-lg-6 col-md-12 col-sm-12 align-self-center pb-maxmd'>
          <div className='w-lg-75 mx-auto'>
            <h3 className='text-uppercase text-primary'>WUNDERBARE LAGE</h3>
            <h2 className='text-uppercase mb-4'>
              MITTEN IM SCHÖNEN SCHINZNACH
            </h2>
            <p className='pb-4'>
              Schinznach mit den Ortsteilen Wallbach, Oberflachs und
              Schinznach-Dorf bildet das Zentrum des Schenkenbergertals. Die
              grösste Rebbaugemeinde des Kantons ist ländlich geprägt, zentral
              gelegen und sowohl mit öffentlichen Verkehrsmitteln wie auch
              individuell sehr gut erreichbar.
            </p>
            {/*  <a href="#" className="btn btn-outline-primary" role="button">Check availability</a> */}
          </div>
        </div>
        <div className='col-lg-6 col-md-12 col-sm-12 align-self-center text-center'>
          <img
            className='img-fluid'
            src='/assets/imagewebp/home-3/modern-furniture.webp'
            alt='schinznach-dorf'
          />
        </div>
      </div>
    </div>
  );
};

export default LocationBenefit;
