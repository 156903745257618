import React from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';
import Textarea from 'react-validation/build/textarea'
import {isEmail, isEmpty, isMobilePhone} from 'validator';
import './contact-form.scss';
import { useState } from 'react';

const ContactForm = () => {
  /*   constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            email: '',
            content: ''
        }
    } */
    const [state, setState] = useState({
        name: '',
        phone: '',
        email: '',
        content: '' 
    })

    const required = (value) => {
        if (isEmpty(value)) {
            return <small className="form-text text-danger">This field is required</small>;
        }
    }

    const email = (value) => {
        if (!isEmail(value)) {
            return <small className="form-text text-danger">Invalid email format</small>;
        }
    }

   const phone = (value) => {
        if (!isMobilePhone(value)) {
            return <small className="form-text text-danger">Invalid Phone number format</small>;
        }
    }

/*     const onSubmit = (e) => {
        e.preventDefault();
        validateAll();

        if (checkBtn.context._errors.length === 0) {
            alert(' success!' + '\n name: ' + state.name + '\n email: ' + state.email + '\n phone:' + state.phone + '\n content:' + state.content + '');
        }
    } */
    console.log('hegege')
console.log(state.name)
        return (
            <Form className="form-group-v1" onSubmit={e => console.log('hello')}>
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                            <Input name="name" onChange={(e) => {setState({name: e.target.value})}} value={state.name} type="text" placeholder="Your name *" className="form-control" validations={[required]}/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                            <Input name="phone" onChange={(e) => {setState({phone: e.target.value})}}  value={state.phone} type="text" placeholder="Phone number *" className="form-control" validations={[required, phone]}/>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <Input name="email" onChange={(e) => {setState({email: e.target.value})}} value={state.email} type="email" placeholder="Your e-mail *" className="form-control" validations={[required, email]}/>
                </div>

                <div className="form-group">
                    <Textarea onChange={(e) => {setState({content: e.target.value})}}  value={state.content} className="form-control" placeholder="How can we help you ?" rows="3" validations={[required]}/>
                </div>
                <button type="submit" className="btn btn-primary mt-4 btn-block">Submit</button> 
                <CheckButton style={{display: 'none'}}/>
            </Form>
        )
}

export default ContactForm