import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
// Import custom components
import SliderHome from './common/slider/slider';
import CounterBlockBg from './common/countup/countup-bg';
import LocationBenefit from './sections/location-benefit/location-benefit';
import SustainableLiving from './sections/sustainable-living/sustainable-living';
import GenerationFriendly from './sections/generation-friendly/generation-friendly';
import ProximityToAmenities from './sections/proximity-to-amenities/proximity-to-amenities';
import CallToActionSection from './sections/caltoaction/caltoaction';
import ApartmentsPlansSection from './sections/ourvalues/ourvalues';
import NeighborhoodsSection from './sections/neighborhoods/neighborhoods';
class Home1 extends Component {
  render() {
    return (
      <div className={`home-page-wrap`}>
        <Helmet>
          <title>MaisonCo | Single Property</title>
        </Helmet>
        <div className='section-wrapper pt-0 pb-0'>
          <SliderHome />
        </div>

        <div className='section-wrapper pt-0'>
          <div className='building-counter text-center'>
            <div className='text-description pt-4 pb-5'>
              <h3 className='text-primary'>WOHNEN MIT STIL UND CHARME</h3>
              <h4>1½ bis 6½-Zimmer-Eigentumswohnungen</h4>
              {/*      <p>
                Am Fusse des Uetliberges in Zürich-Altstetten entstehen 21
                helle, behagliche Wohnungen mit Stil und Charme. Das
                anschauliche Objekt mit gehobenem Ausbaustandard liegt zentral
                in einem schönen Wohnquartier, von wo man den Bahnhof,
                Einkaufsläden, Schulen etc. schnell erreichen kann.
              </p> */}
            </div>
            <CounterBlockBg />
            <div className='pt-3'>
              <a
                href='#'
                className='pt-2 btn btn-outline-primary'
                role='button'
              >
                UNSERE LIEGENSHAFTEN
              </a>
            </div>
          </div>
        </div>

        <div className='section-wrapper p-0'>
          <LocationBenefit />
        </div>

        <div className='section-wrapper p-0'>
          <SustainableLiving />
        </div>

        <div className='section-wrapper p-0'>
          <GenerationFriendly />
        </div>

        <div className='section-wrapper p-0'>
          <ProximityToAmenities />
        </div>

        <div className='section-wrapper'>
          <ApartmentsPlansSection />
        </div>
      </div>
    );
  }
}

export default Home1;
