import React from 'react';
import MainMenu from './common/navbar';
import LogoImage from './common/logo';

const Header = (menu) => {
  const { logoName } = menu;

  return (
    <header id='masthead' className='site-header'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-around p-2'>
          <div className='site-branding col-lg-6'>
            <LogoImage logo={logoName} />
          </div>
          <div className='col-lg-6 desktop-menu'>
            <MainMenu menu={menu} />
          </div>
          <div className='header-group d-flex'>
            <div className='rpm-trigger header-button' id='rpm-trigger'>
              <i className={`opal-icon opal-icon-toggle`}></i>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
